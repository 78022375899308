import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.css"
import './styles/App.css';
import './styles/loader.css';
import './styles/NavBar.scss';

import './styles/day/AI.scss'
import './styles/day/User.scss'

import './styles/night/AI.scss'
import './styles/night/User.scss'
import './styles/night/NavBar.scss'

import './styles/colours/Red.scss'
import './styles/colours/Blue.scss'
import './styles/colours/Green.scss'
import './styles/colours/Yellow.scss'

import './styles/size/Small.scss'
import './styles/size/Medium.scss'
import './styles/size/Large.scss'

import './styles/size/Bold.scss'

import './styles/day/Login.scss'
import './styles/day/Launch.scss'

import App from './App';

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/msalConfig";
import backend_api from "./utils/BackendApiCaller"
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(async () => {
  backend_api.apiStatus()
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  document.body.classList.add('medium');

  root.render(
    <Router>
        <App pca={msalInstance} />
    </Router>
  );
});