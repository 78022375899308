import React, { useRef, useState, useEffect } from "react"

import useAutosizeTextArea from "./useAutosizeTextArea.jsx";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'

export default function Input({ onSend, handleFileChange, fileList, fileUploaded, deleteFile }) {
    const [value, setValue] = useState("")
    const textAreaRef = useRef(null)

    const handleSend = e => {
        e.preventDefault();
        onSend(value)
        setValue("")
    }

    const onEnterPress = e => {
        if (e.code === "Enter" && e.shiftKey == false) {
            console.log("Enter key was pressed. Run your function.");
            e.preventDefault();
            handleSend(e);
        }
    }

    const handleChange = evt => {
        const val = evt.target?.value

        setValue(val)
    }
    
    useAutosizeTextArea(textAreaRef.current, value)

    return (
        <>
            <div className="abacus-input-container">
                <form onSubmit={handleSend} className="abacus-input-box">
                    <textarea
                        ref={textAreaRef}
                        rows={1}
                        value={value}
                        placeholder="Ask your question..."
                        onChange={handleChange}
                        onKeyDown={onEnterPress}
                    ></textarea>
                    <input id='input-file' multiple type="file" onChange={handleFileChange} />
                    {fileUploaded ? (
                        <FontAwesomeIcon className={`abacus-input-upload`} icon={byPrefixAndName.fas['trash']} onClick={deleteFile} />
                    ) : (
                        <>
                            <label for='input-file' className="abacus-input-upload-label"><FontAwesomeIcon className={`abacus-input-upload`} icon={byPrefixAndName.fas['folder-arrow-up']} /></label>
                        </>
                    )}
                </form >
                {fileUploaded ? (
                    <div className="abacus-uploaded-files">
                        {fileList.map((file) =>
                            <div className="abacus-uploaded-file">
                                <p>
                                    {file}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (<></>)}
            </div>
        </>
    )
}
