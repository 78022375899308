import React, { useState, useEffect, useRef } from "react"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import API from "./chatBot";
import { styled } from '@mui/material/styles';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Button from '@mui/material/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'


import { loginRequest } from "../../config/msalConfig";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import backend_api from "../../utils/BackendApiCaller"
import { callMsGraph, callMsGraphTenant } from "../../utils/MsGraphApiCall";
let nextId = 0;

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
        },
    },
};

export default function BotMessage({ history, fetchMessage, updateAiResponse, downloadPDF, getBlob, getBlobURL }) {
    const [isLoading, setLoading] = useState(true)
    const [aiModel, setAiModel] = useState("nuwai")
    const [message, setMessage] = useState("<p></p>")
    const [sources, setSources] = useState([])
    const [messageOption, setMessageOption] = useState("")

    const [feedbackState, setFeedbackState] = useState("")
    const [feedbackName, setFeedbackName] = useState("")
    const [feedbackEmail, setFeedbackEmail] = useState("")
    const [feedbackType, setFeedbackType] = useState("")
    const [feedback, setFeedback] = useState("")

    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const { instance, inProgress, accounts } = useMsal();

    function GradientCircularProgress() {
        return (
            <React.Fragment>
                <svg width={0} height={0}>
                    <defs>
                        <linearGradient id="nuwaru-grad" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#F29E49" />
                            <stop offset="100%" stopColor="#ED704A" />
                        </linearGradient>
                    </defs>
                </svg>
                <CircularProgress className="ai-loading" sx={{ 'svg circle': { stroke: 'url(#nuwaru-grad)' } }} />
            </React.Fragment>
        );
    }

    const handleOptionChange = async (event, fileName, location) => {
        setMessageOption(event.target.value);

        var optionChoice = event.target.value;
        console.log(optionChoice)
        if (optionChoice == "viewDocument") {
            getBlob(fileName, location)
            setMessageOption("");
        } else if (optionChoice == "newTab") {
            console.log("Option Choice", optionChoice)
            var fileURL = await getBlobURL(fileName, location)
            window.open(fileURL, "_blank", 'noopener,noreferrer')
            var fileURL = ""
            setMessageOption("");

        } else if (optionChoice == "download") {
            console.log("Option Choice", optionChoice)
            console.log(fileName)
            console.log(location)
            downloadPDF(fileName, location)
            setMessageOption("");
        }
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
        }
    };

    const openFeedback = (event) => {
        if (feedbackState == "active") {
            setFeedbackState("")
        } else {
            setFeedbackState("active")
        }
    }

    const handleFeedbackType = async (event) => {
        setFeedbackType(event.target.value);
    }

    const handleFeedbackName = (event) => {
        setFeedbackName(event.target.value)
    }

    const handleFeedbackEmail = (event) => {
        setFeedbackEmail(event.target.value)
    }

    const handleFeedback = (event) => {
        setFeedback(event.target.value)
    }

    const submitFeedback = async (e) => {
        e.preventDefault();
        console.log(feedbackName)
        console.log(feedbackEmail)
        console.log(feedbackType)
        console.log(feedback)
        console.log(history);

        setFeedbackState("")

        const response = await API.SubmitFeedback(feedbackName, feedbackEmail, feedbackType, feedback, history)

        setFeedbackType("")
        setFeedbackName("")
        setFeedbackEmail("")
        setFeedback("")
        setMessageOption("")
    }

    const closeFeedback = (e) => {
        e.preventDefault();
        setFeedbackType("")
        setFeedbackName("")
        setFeedbackEmail("")
        setFeedback("")

        setFeedbackState("")
        setMessageOption("")
    }

    const copyMessage = e => {
        e.preventDefault();
        console.log("Copy to clipboard")
        navigator.clipboard.writeText(message);
        handleClick();
    }

    useEffect(() => {
        console.log("BOT MSG - ID ", history.id)
        console.log("BOT MSG - Session ID", history.session_id)
        console.log("BOT MSG - Model ", history.model)
        console.log("BOT MSG - Question ", history.question)
        console.log("BOT MSG - References ", history.references)
        console.log("BOT MSG - Response ", history.response)
        console.log("BOT MSG - Sources ", history.sources)
        console.log("BOT MSG - Latest ", history.latest)
        console.log("BOT MSG - Tone ", history.tone)
        console.log("BOT MSG - Input Tokens ", history.inputTokens)
        console.log("BOT MSG - Output Tokens ", history.outputTokens)
        console.log("BOT MSG - Total Tokens ", history.totalTokens)

        const accessTokenRequestAPI = {
            scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
            account: accounts[0],
        };

        const loadMessage = async () => {
            if (fetchMessage) {
                try {

                    if (inProgress === InteractionStatus.None) {
                        callMsGraphTenant()
                            .then(response => {
                                var tenantDetails = {
                                    "tid": response.value[0].id,
                                    "tdn": response.value[0].displayName
                                }

                                instance
                                    .acquireTokenSilent(accessTokenRequestAPI)
                                    .then(async (accessTokenResponse) => {
                                        let accessToken = accessTokenResponse.accessToken;

                                        await backend_api.addQuestion(accessToken, tenantDetails, history)

                                        const responseObject = await fetchMessage(accessToken, tenantDetails, history)
                                        console.log("New Response Design: ", responseObject)
                                        setAiModel(history.model);
                                        setLoading(false)
                                        setMessage(responseObject.response)

                                        await backend_api.addResponse(accessToken, tenantDetails, history, responseObject.response, responseObject.sources, responseObject.input_tokens, responseObject.output_tokens, responseObject.total_tokens)

                                        const refined_sources = responseObject.sources.filter((obj1, i, arr) =>
                                            arr.findIndex(obj2 =>
                                                ['fileName', 'linesFrom', 'linesTo'].every(key => obj2[key] === obj1[key])
                                            ) === i
                                        )

                                        setSources(refined_sources)

                                        if (updateAiResponse) {
                                            updateAiResponse(responseObject, history.id);
                                        }
                                    })
                                    .catch((error) => {
                                        if (error instanceof InteractionRequiredAuthError) {
                                            instance.acquireTokenRedirect(accessTokenRequestAPI);
                                        }
                                        console.log(error);
                                    });
                            })
                            .catch((e) => {
                                if (e instanceof InteractionRequiredAuthError) {
                                    instance.acquireTokenRedirect({
                                        ...loginRequest,
                                        account: instance.getActiveAccount()
                                    });
                                }
                            })
                    }
                } catch (error) {
                    console.log("Error", error);
                }
            } else {
                setSources(history.sources)
                setAiModel(history.model);
                setLoading(false)
                setMessage(history.response)
            }
        }

        if (history.id == 0 || history.type == "status") {
            setAiModel(history.model);
            setMessage(history.response);
            setLoading(false)
        } else {
            loadMessage();
        }
    }, [inProgress, accounts, instance])

    return (
        <>
            <div className={`abacus-message-container`}>
                <div className="abacus-action-container">
                    <div className="abacus-action-logo">

                    </div>
                    <div className="abacus-action-feedback">
                        <FontAwesomeIcon className={`abacus-action-feedback-icon thumbsUp`} icon={byPrefixAndName.fasds['thumbs-up']} />
                        <FontAwesomeIcon className={`abacus-action-feedback-icon thumbsDown`} icon={byPrefixAndName.fasds['thumbs-down']} />
                    </div>
                    <div className="abacus-action-settings">
                        <FontAwesomeIcon className={`abacus-action-settings-icon copy`} icon={byPrefixAndName.fasds['copy']} onClick={copyMessage}/>
                        <FontAwesomeIcon className={`abacus-action-settings-icon settings`} icon={byPrefixAndName.fasds['gear']} />
                    </div>
                </div>
                <div className="abacus-message-blob">
                    {isLoading ?
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        :
                        <>
                            <div className="abacus-message">
                                <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
                            </div>
                            {history.type == "status" || sources.length == 0 ? <>
                            </> :
                                <div className="abacus-sources">
                                    <div className="abacus-sources-header">
                                        <FontAwesomeIcon className={`abacus-message-sources-icon`} icon={byPrefixAndName.fas['book-open-cover']} />
                                        <h2>Sources</h2>
                                    </div>
                                    <div className="abacus-sources-list-group">
                                        {sources.map((source, index) => (
                                            <div className="abacus-sources-list">
                                                <div>
                                                    <p className="source-name">
                                                        {index + 1}. {source.fileName}
                                                    </p>
                                                    <p className="source-lines">
                                                        Lines: {source.linesFrom} - {source.linesTo}
                                                    </p>
                                                </div>
                                                <div className="source-button-container">
                                                    <FormControl id="abacus-message-options" sx={{ m: 1, minWidth: 120 }}>
                                                        <InputLabel id="abacus-message-options-input">Options</InputLabel>
                                                        <Select
                                                            labelId="abacus-message-options-label"
                                                            id="abacus-message-options-select"
                                                            value={messageOption}
                                                            onChange={(e) => handleOptionChange(e, source.fileName, source.location)}
                                                            autoWidth
                                                            label="Options"
                                                        >
                                                            <MenuItem id="aabacusi-message-options-item" value={"viewDocument"}>View document</MenuItem>
                                                            <MenuItem id="abacus-message-options-item" value={"newTab"}>Open in new tab</MenuItem>
                                                            <MenuItem id="abacus-message-options-item" value={"download"}>Download</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                        </>
                    }
                </div >
            </div >
            {/* {isLoading || history.type == "status" ? <>
            </> :
                history.latest ?
                    <div className="ai-message-settings">
                        <FormControl id="ai-message-options" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="ai-message-options-input">Options</InputLabel>
                            <Select
                                labelId="ai-message-options-label"
                                id="ai-message-options-select"
                                value={messageOption}
                                onChange={handleOptionChange}
                                autoWidth
                                label="Options"
                            >
                                <MenuItem id="ai-message-options-item" value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem id="ai-message-options-item" value={"simple"}>Simplify Response</MenuItem>
                                <MenuItem id="ai-message-options-item" value={"tax-advisor"}>Format as Tax Advisor</MenuItem>
                                <MenuItem id="ai-message-options-item" value={"copy"}>Copy Response</MenuItem>
                            </Select>
                        </FormControl>
                        <button className="ai-message-feedback-btn" onClick={openFeedback}>Submit Feedback</button>
                    </div> :
                    <div className="ai-message-settings">
                        <FormControl id="ai-message-options" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="ai-message-options-input">Options</InputLabel>
                            <Select
                                labelId="ai-message-options-label"
                                id="ai-message-options-select"
                                value={messageOption}
                                onChange={handleOptionChange}
                                autoWidth
                                label="Options"
                            >
                                <MenuItem id="ai-message-options-item" value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem id="ai-message-options-item" value={"copy"}>Copy Response</MenuItem>
                            </Select>
                        </FormControl>
                        <button className="ai-message-feedback-btn" onClick={openFeedback}>Submit Feedback</button>
                    </div>
            }

            <div className={`feedback-modal ${feedbackState}`}>
                <form onSubmit={submitFeedback}>
                    <div class="feedback-modal__content">
                        <h1 className="feedback-modal-heading">NuwAI Feedback</h1>
                        <div className="feedback-modal-form">
                            <TextField id="ai-feedback-textfield" value={feedbackName} label="Name" variant="outlined" className="ai-feedback-field textfield" onChange={handleFeedbackName} />
                            <TextField id="ai-feedback-textfield" value={feedbackEmail} label="Email" variant="outlined" className="ai-feedback-field textfield" onChange={handleFeedbackEmail} />
                            <FormControl id="ai-feedback-options" className="ai-feedback-field" sx={{ m: 1, minWidth: 300 }}>
                                <InputLabel id="ai-feedback-options-input">Options</InputLabel>
                                <Select
                                    labelId="ai-feedback-options-label"
                                    id="ai-feedback-options-select"
                                    value={feedbackType}
                                    onChange={handleFeedbackType}
                                    autoWidth
                                    label="Type"
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem id="ai-feedback-options-item" value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem id="ai-feedback-options-item" value={"AI Responses"}>AI Responses</MenuItem>
                                    <MenuItem id="ai-feedback-options-item" value={"User Interface"}>User Interface</MenuItem>
                                    <MenuItem id="ai-feedback-options-item" value={"Performance"}>Performance</MenuItem>
                                    <MenuItem id="ai-feedback-options-item" value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                value={feedback}
                                id="ai-feedback-textfield"
                                className="ai-feedback-field textfield multiline"
                                label="Feedback"
                                multiline
                                maxRows={10}
                                onChange={handleFeedback}
                            />
                            <button type="submit" className="ai-feedback-submit"><p alt="Submit" className="ai-feedback-submit-btn">Submit</p></button>
                            <button onClick={closeFeedback} className="ai-feedback-cancel"><p alt="Cancel" className="ai-feedback-cancel-btn">Cancel</p></button>
                        </div>
                    </div>
                </form>
            </div> */}

            < Snackbar
                anchorOrigin={{ vertical, horizontal }
                }
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                key={vertical + horizontal}
                message="AI Response copied to clipboard."
            />
        </>
    )
}
