import React, { useState, useEffect, useRef } from "react"
import { ReactComponent as CopyIcon } from '../../assets/copy.svg'
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'


export default function UserMessage({ text, userInitials }) {
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const copyMessage = e => {
        e.preventDefault();
        console.log("Copy to clipboard")
        navigator.clipboard.writeText(text);
        handleClick();
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
        }
    };

    return (
        <div className={`abacus-user-container`}>
            <div>
                <div className="abacus-user-message">
                    <p>{text}</p>
                </div>
            </div>
            <div className="abacus-action-container user">
                <div className="abacus-action-logo user">
                    <p>{userInitials}</p>
                </div>
                <div className="abacus-action-settings">
                    <FontAwesomeIcon className={`abacus-action-settings-icon copy`} icon={byPrefixAndName.fasds['copy']} onClick={copyMessage} />
                    <FontAwesomeIcon className={`abacus-action-settings-icon settings`} icon={byPrefixAndName.fasds['pen-to-square']} />
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                key={vertical + horizontal}
                message="Question copied to clipboard."
            />
        </div>
    )
}
